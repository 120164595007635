@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;700&family=Quicksand:wght@400;700&display=swap');

.MuiList-root {
  max-height: 300px;
}

.custom-overlay-class {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

.ReactModal__Content {
  border: none !important;
  background: transparent !important;
  overflow: hidden !important;
  border-radius: 4px;
  outline: none;
  padding: 0 !important;
  max-width: 840px !important;
  max-height: 800px !important;
  width: 100% !important;
  height: 100% !important;
  left: 50% !important;
  top: 50% !important;
  transform: translate(-50%, -50%);
}

.ReactModal__Content img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.ReactModal__Content button {
  position: absolute;
  z-index: 999;
  margin-top: 1rem;
  align-self: flex-start;
  background-color: #d3584d;
  cursor: pointer;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 6px;
  -webkit-text-decoration: none;
  text-decoration: none;
  color: #333;
  border-radius: 3px;
  padding: 0.5rem 1.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReactModal__Content button svg {
  color: #fff;
  font-size: 30px;
}

.ReactModal__Content button.prev {
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.ReactModal__Content button.next {
  right: 15px;
  top: 50%;
  transform: translateY(-50%);
}

.rhap_main-controls-button svg {
  color: #17c0eb;
}
.rhap_progress-filled,
.rhap_progress-indicator {
  background-color: #17c0eb !important;
}

.rhap_button-clear.rhap_volume-button {
  color: #17c0eb !important;
}

.rhap_volume-bar,
.rhap_volume-indicator {
  background-color: #17c0eb;
}

@media (max-width: 1200px) {
  .ReactModal__Content {
    max-width: 600px !important;
    height: 100% !important;
  }
}

@media (max-width: 768px) {
  .ReactModal__Content {
    max-width: 500px !important;
    width: 100% !important;
    max-height: 800px !important;
    height: 100% !important;
    margin-top: 50px;
  }

  .ReactModal__Content button {
    padding: 0.2rem 0.8rem;
  }
}

@media (max-width: 576px) {
  .ReactModal__Content {
    max-width: 400px !important;
    width: 100% !important;
    max-height: 600px !important;
    height: 100% !important;
  }
}

@media (max-width: 476px) {
  .ReactModal__Content {
    max-width: 320px !important;
    width: 100% !important;
    max-height: 450px !important;
    height: 100% !important;
  }
}
